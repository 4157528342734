body {
  margin: 0;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.root {
  height: 100%;
}
.preview-mask {
  background-color: rgba(0, 0, 0, 0.5)!important;
}
.PhotoView-PhotoSlider__BannerWrap {
  background-color: rgba(0, 0, 0, 0);
}
.PhotoView-PhotoSlider__Counter {
  opacity: 0;
}

model-viewer {
  --poster-color: rgba(0 , 0, 0, 0);
  --poster-image: rgba(0 , 0, 0, 0);
}
